import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlantMapperResponse } from '@shared/models/plant-mapper-response';
import { SmoketestSetting } from '@shared/models/smoketest/smoketest-setting';
import { ZertArt } from '@shared/models/smoketest/zert-art';
import { VedocData } from '@shared/models/vedoc-data';
import { ApiService } from '@shared/services/api/api.service';
import { PlantMapperService } from '@shared/services/plant-mapper/plant-mapper.service';
import { SwitchService } from '@shared/services/switch/switch.service';
import { ZkeService } from '@shared/services/zke/zke.service';
import { seriesValidator } from '@shared/validators/series-validator';
import { vinValidator } from '@shared/validators/vin-validator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
    Mode,
    SmoketestSettingDetailResult,
} from './models/smoketest-setting-detail-result';
import { SmoketestSettingsService } from '@shared/services/smoketest-settings/smoketest-settings.service';

@Component({
    selector: 'app-smoketest-setting-detail',
    templateUrl: './smoketest-setting-detail.component.html',
    styleUrls: ['./smoketest-setting-detail.component.scss'],
})
export class SmoketestSettingDetailComponent implements OnInit, OnDestroy {
    @Input()
    smoketestSetting: SmoketestSetting;
    @Input()
    mode: Mode;

    MODE = Mode;
    form: UntypedFormGroup;

    private notifier = new Subject();
    private ROOT_URL: string;
    isSmokeTestSkippable = false;
    isSkippable: boolean = true;
    selectedETMOption: string = '';
    ETMDisabled: boolean = false;
    isZertValidationRequired: any;
    showETMMsg: boolean = false;
    isLoading: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private activeModal: NgbActiveModal,
        private switchService: SwitchService,
        private apiService: ApiService,
        private zkeService: ZkeService,
        private plantMapperService: PlantMapperService,
        private smoketestSettingsService: SmoketestSettingsService,
    ) {}

    ngOnInit(): void {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'ROOT',
                    data
                );
            });

        this.form = this.fb.group({
            fin: new UntypedFormControl(null, [
                Validators.required,
                Validators.minLength(17),
                Validators.maxLength(17),
                vinValidator,
            ]),
            baureihe: new UntypedFormControl(null, [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(4),
                seriesValidator,
            ]),
            ttz: new UntypedFormControl(null, [Validators.required]),
            werkskennung: new UntypedFormControl(null, [Validators.required]),
            codes: new UntypedFormControl(null, [Validators.required]),
            zertArt: new UntypedFormControl(null),
            skipable : false
        });

        if (this.smoketestSetting) {
            this.patchForm();
        }

        this.form.get('baureihe')?.valueChanges.subscribe((value) => {
            if (this.form.get('baureihe')?.valid) {
                this.getETMStatus(value);
            }
        });
    }

    getZertValidators() {
        return this.isZertValidationRequired && !this.form.get('skipable').value ? [Validators.required] : [];
    }

    toggleZertValidation() {
        const ZertControl = this.form.get('zertArt');
        ZertControl?.clearValidators();
        ZertControl?.setValidators(this.getZertValidators());
        ZertControl?.updateValueAndValidity();
      }


    getETMStatus(baureihe): void {
        this.isLoading = true;
        this.smoketestSettingsService.getETMStatus(this.ROOT_URL, baureihe).subscribe(value => {
            this.selectedETMOption = value === true ? 'ETM' : value === false ? 'CEBAS' : null;
            this.form.get('zertArt')?.setValue(this.selectedETMOption);
            this.ETMDisabled = value === null ? false : true;
            this.isZertValidationRequired = value === null ? true : false;
            this.showETMMsg = value === null ? true : false;
            this.toggleZertValidation();
            this.isLoading = false;
        },
        _error => {
          this.isLoading = false;              
        });
    }

    loadData() {
        const vin = this.form.controls.fin.value;
        this.zkeService.getVedocData(vin).subscribe((vedocData: VedocData) => {
            this.plantMapperService
                .getByFin(this.ROOT_URL, vedocData.fin)
                .subscribe((plantMapperResponse: PlantMapperResponse) => {
                    this.smoketestSetting = new SmoketestSetting(
                        vedocData.fin,
                        `C${vedocData.fin.substring(3, 6)}`,
                        vedocData.ttz,
                        parseInt(plantMapperResponse.code, 10),
                        vedocData.codes.join(','),
                        null,
                        this.isSmokeTestSkippable
                    );
                    this.patchForm();
                });
        });
    }

    handleSmokeTestSkippable(){
        const isSkippable = this.form.get(
            'skipable'
          );
          this.isSkippable = isSkippable.value;
            const finControl = this.form.get('fin');
            const codesControl = this.form.get('codes');
            const ttzControl = this.form.get('ttz');
            const werkskennungControl = this.form.get('werkskennung');
          if (isSkippable.value) {
            finControl?.setValidators([Validators.required]);
            codesControl?.setValidators([Validators.required]);
            ttzControl?.setValidators([Validators.required]);
            werkskennungControl?.setValidators([Validators.required]);
          } else {
            finControl?.clearValidators();
            codesControl?.clearValidators();
            ttzControl?.clearValidators();
            werkskennungControl?.clearValidators();
          }
          this.isLoading = true;
          this.smoketestSettingsService.getETMStatus(this.ROOT_URL, this.form.get('baureihe')?.value).subscribe(value => {
            this.selectedETMOption = value === true ? 'ETM' : value === false ? 'CEBAS' : null;
            this.form.get('zertArt')?.setValue(this.selectedETMOption);
            this.ETMDisabled = value === null ? false : true;
            this.isZertValidationRequired = value === null ? true : false;
            this.showETMMsg = value === null ? true : false;
            this.toggleZertValidation();
            this.isLoading = false;
          },
          _error => {
            this.isLoading = false;              
          });
          finControl?.updateValueAndValidity();
          codesControl?.updateValueAndValidity();
          ttzControl?.updateValueAndValidity();
          werkskennungControl?.updateValueAndValidity();
          
          isSkippable?.setValue(!isSkippable.value);
          this.isSmokeTestSkippable = !this.isSmokeTestSkippable;
    }

    cancel() {
        this.activeModal.dismiss();
    }

    save() {
        const result = new SmoketestSettingDetailResult(
            this.form.value as SmoketestSetting,
            this.mode
        );
        this.activeModal.close(result);
    }

    inputIsInvalid(control: string): boolean {
        return this.form.get(control).errors && this.form.get(control).touched;
    }

    private patchForm() {
        this.form.patchValue({
            fin: this.smoketestSetting.fin,
            baureihe: this.smoketestSetting.baureihe,
            ttz: this.smoketestSetting.ttz,
            werkskennung: this.smoketestSetting.werkskennung,
            codes: this.smoketestSetting.codes,
            zertArt: this.smoketestSetting.zertArt,
            skipable : this.smoketestSetting.skipable
        });
    }

    ngOnDestroy(): void {
        this.notifier.next();
        this.notifier.complete();
    }

    protected readonly ZertArt = ZertArt;
}
