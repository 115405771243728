import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@shared/constants/paths';
import { Page } from '@shared/models/page';
import { SmoketestSetting } from '@shared/models/smoketest/smoketest-setting';
import { SmoketestSettingPage } from '@shared/models/smoketest/smoketest-setting-page';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SmoketestSettingsService {
    private smoketestSettingsRoute = 'api/smoketest-data';

    constructor(private http: HttpClient) {}

    get(url: string, page: Page): Observable<SmoketestSettingPage> {
        const queryString = this.buildPaginationQueryString(page);
        return this.http.get<SmoketestSettingPage>(
            `${url}/${apiPaths.controller}/${this.smoketestSettingsRoute}${queryString}`
        );
    }

    save(url: string, smoketestSetting: SmoketestSetting) {
        return this.http.post(
            `${url}/${apiPaths.controller}/${this.smoketestSettingsRoute}`,
            smoketestSetting
        );
    }

    update(url: string, smoketestSetting: SmoketestSetting) {
        return this.http.put(
            `${url}/${apiPaths.controller}/${this.smoketestSettingsRoute}/${smoketestSetting.baureihe}`,
            smoketestSetting
        );
    }

    delete(url: string, baureihe: string) {
        return this.http.delete(
            `${url}/${apiPaths.controller}/${this.smoketestSettingsRoute}/${baureihe}`
        );
    }

    private buildPaginationQueryString(page: Page): string {
        let queryString = '';
        Object.keys(page).forEach((key, index) => {
            if (page[key] !== null && page[key] !== undefined) {
                queryString += `${index === 0 ? '?' : '&'}${key}=${page[key]}`;
            }
        });
        return queryString;
    }

    getETMStatus(url: string, baureihe: string): Observable<any> {
        return this.http.get(
            `${url}/${apiPaths.dataManager}/api/check/etm/${baureihe}`
        );
    }
}
